<template>
  <div class="declare">
    <div>
      <div class="table">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="create_time" label="日期"></el-table-column>
          <el-table-column prop="branch" label="部门"></el-table-column>
          <el-table-column prop="com_name" label="姓名"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handle_put(scope.row)"
              >编辑</el-button> -->
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleput(scope.row)"
              >详情</el-button>
              <!-- <el-button
                type="primary"
                size="mini"
                icon="el-icon-delete"
                @click="handleDel( scope.row)"
              >删除</el-button>-->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye">
        <el-pagination
          layout="prev, pager, next"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="增加子账号" :visible.sync="centerDialogVisible" width="30%" center>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item label="昵称" prop="com_name">
          <el-input v-model="ruleForm.com_name"></el-input>
        </el-form-item>-->
        <el-form-item label="姓名" prop="user_name">
          <el-input v-model="ruleForm.user_name"></el-input>
         
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="branch">
          <el-input v-model="ruleForm.branch"></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名" prop="user_name">
          <el-input v-model="ruleForm.user_name"></el-input>
        </el-form-item>-->
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="权限" prop="group_id">
          <el-select v-model="ruleForm.group_id" placeholder="请选择权限组">
            <el-option
              :label="item.group_name"
              :value="item.id"
              v-for="(item,index) in group"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click=" = false">取 消</el-button> -->
        <!-- <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="Visible" width="30%" center>
      <div class="xiangqing">
        <div>
          <div>姓名</div>
          <div>{{ruleForm.user_name}}</div>
        </div>
        <div>
          <div>部门</div>
          <div>{{ruleForm.branch}}</div>
        </div>
        <!-- <div>
          <div>姓名</div>
          <div>{{ruleForm.user_name}}</div>
        </div>-->
        <div>
          <div>电话</div>
          <div>{{ruleForm.mobile}}</div>
        </div>
        <div>
          <div>邮箱</div>
          <div>{{ruleForm.email}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Visible = false">取 消</el-button>
        <el-button type="primary" @click="Visible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export function checkPhoneNumber(rule, value, callback) {
  const reg = /^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!value) {
    return callback(new Error("请填写手机号码！"));
  } else if (!reg.test(value)) {
    return callback(new Error("请填写正确的手机号码！"));
  } else {
    callback();
  }
}
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
import { group_list, user_list, user_edit } from "../axios/api";
export default {
  data() {
    return {
      Visible: false,
      centerDialogVisible: false,
      tableData: [],
      group: [],
      page: "1",
      total: 0,
      pageSize: 0,
      rules: {
        // com_name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        branch: [{ required: true, message: "请输入部门", trigger: "blur" }],
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, validator: checkPhoneNumber, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        group_id: [
          { required: true, message: "请选择权限组", trigger: "change" },
        ],
      },
      ruleForm: {
        com_name: "",
        password: "",
        branch: "",
        mobile: "",
        email: "",
        group_id: "",
      },
    };
  },
  created() {
    this.group_list();
    this.user_list();
  },
  components: {},

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          this.ruleForm.token = sessionStorage.getItem("Token");
          user_edit(this.ruleForm).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.resetForm("ruleForm");
              this.centerDialogVisible = false;
            } else {
              this.$message.error(res.data.msg);
            }
            this;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.centerDialogVisible = false;
      this.user_list();
    },
    handle_put(e) {
      console.log(e);
      this.ruleForm.com_name = e.com_name;
      this.ruleForm.user_name = e.user_name;
      // this.ruleForm.password = e.password;
      this.ruleForm.branch = e.branch;
      this.ruleForm.mobile = e.mobile;
      this.ruleForm.group_id = e.group_id;
      this.ruleForm.email = e.email;
      this.ruleForm.id = e.id;
      this.centerDialogVisible = true;
    },
    user_list() {
      let params = {
        token: sessionStorage.getItem("Token"),
        page: this.page,
      };
      user_list(params).then((res) => {
        console.log(res);
        this.total = parseInt(res.data.data.totalPage);
        this.pageSize = parseInt(res.data.data.pageSize);
        this.tableData = res.data.data.data;
        this.tableData.forEach((item) => {
          var myDate = new Date(item.create_time * 1000);
          myDate.setDate(myDate.getDate());
          console.log(myDate.setDate(myDate.getDate()));
          item.create_time = myDate.format("yyyy年MM月dd日");
        });
      });
    },
    group_list() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      group_list(params).then((res) => {
        console.log(res);
        this.group = res.data.data;
      });
    },
    tiaozhuan() {
      // this.$router.push({ path: "/index" });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val + "";
      this.user_list();
    },

    handleput(e) {
      console.log(e);
      this.ruleForm.com_name = e.com_name;
      this.ruleForm.user_name = e.user_name;
      this.ruleForm.password = e.password;
      this.ruleForm.branch = e.branch;
      this.ruleForm.mobile = e.mobile;
      this.ruleForm.group_id = e.group_id;
      this.ruleForm.email = e.email;
      this.Visible = true;
    },
    handleDel(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
.declare {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px;
}
.table {
  width: 95%;
  height: 80%;
  margin: 0 auto;
}
.xiangqing {
  width: 100%;
  box-sizing: border-box;
}
.xiangqing > div {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}
.fenye {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>